<template>
  <div :class="cn('min-h-screen-nav marker: flex flex-col', $attrs.class!)">
    <LayoutKycNavbar :hint="locale(group, 'hint')" />
    <Suspense>
      <slot />
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { snakeCase } from "lodash-es"

import { Group } from "../types"

const kyc = useKycStore()
const group = ref({} as Group)
const { locale } = useI18nUtils()
const gtm = useGtm()

watch(
  () => kyc.getCurrentGroup,
  (newGroup: Group, oldGroup: Group) => {
    group.value = newGroup

    if (oldGroup !== undefined) {
      const mappedAnswersFromOldGroup = Object.fromEntries(
        oldGroup.questions.map((question, index) => {
          return [
            `kyc__${[question.id, snakeCase(question.descriptionEn)].filter(Boolean).join("_")}`,
            kyc.answers[question.id] ?? undefined,
          ]
        })
      )

      gtm.push({
        event: "kyc_step_complete",
        step: oldGroup.id,
        step_name: oldGroup.descriptionEn,
        ...mappedAnswersFromOldGroup,
      })
    }

    if (newGroup) {
      gtm.push({ event: "kyc_step_enter", step: newGroup.id, step_name: newGroup.descriptionEn })
    }
  }
)
</script>

<style lang="scss">
.kyc {
  line-height: 1;
  height: 100%;
  --answers-container-width: 500px;

  .finq-input {
    label {
      margin-block-end: theme.$spacing-xxs;
    }
  }

  &-questions {
    max-width: var(--answers-container-width);
    margin: auto;
  }

  &-paragraph {
    a {
      color: currentColor;
      text-decoration: underline;
    }
  }
}
</style>

<style lang="scss" scoped>
.kyc-header {
  .navbar__icon:not(.navbar__icon--hidden) {
    pointer-events: all;
  }
}
</style>
