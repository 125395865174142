<template>
  <UiPopoverDrawer
    v-model:model-value="isPopupDrawerOpen"
    :classes="{
      popover: {
        body: 'min-w-[340px] max-w-[340px] overflow-hidden rounded-xl leading-tight',
      },
    }"
  >
    <!-- Trigger -->
    <template #trigger>
      <UiButton
        class="rounded-full border text-base font-medium"
        variant="outline"
        @click="
          () => {
            if (isMobile) isPopupDrawerOpen = true
          }
        "
      >
        {{ t("kyc.note.title") }}
      </UiButton>
    </template>

    <!-- Text -->
    <span v-text="hint" />

    <!-- Mobile Drawer -->
    <template #drawer>
      <UiAToolbar color="white" hide-back border :icons-width="60" :max-height="50" @onClose="toggleTooltip">
        <span class="block text-center" v-text="$t('kyc.note.title')" />
      </UiAToolbar>

      <span class="block overflow-auto p-5" v-text="hint" />
    </template>
  </UiPopoverDrawer>
</template>

<script setup lang="ts">
const { t } = useI18n()
const { isMobile } = useDisplay()
defineProps<{ hint?: string }>()

const isPopupDrawerOpen = ref<boolean>(false)

const toggleTooltip = () => (isPopupDrawerOpen.value = !isPopupDrawerOpen.value)
</script>
